import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Img1 from './../images/首页长图-1@2x.webp'
import Img2 from './../images/首页长图-2@2x.webp'
import Img3 from './../images/首页长图-3@2x.webp'

const IndexPage = () => (
  <Layout>
    <div className="w-[100%]  text-[50px]">
      <img src={Img1}></img>
      <img src={Img2}></img>
      <img src={Img3}></img>
    </div>
    <div className="bg-[#fff] w-[100%] text-black opacity-90 text-[12px] text-center h-10 leading-10 fixed bottom-0 ">2022 © Copyright AIRUDDER （上海赛舵智能科技有限公司）</div>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo  title="Home" />

export default IndexPage
